module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-03R7RKFL4L"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mr Viktor","short_name":"Mr Viktor","description":"Personal website of Viktor Tylecek.","lang":"en","display":"browser","icon":"src/images/icon.png","start_url":"/","background_color":"#fff","theme_color":"#fff","localize":[{"start_url":"/cs/","lang":"cs","name":"Mr Viktor","short_name":"Mr Viktor","description":"Osobní stránky Viktora Tylečka."}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"0a6ff67a08ff8114be70ef622306953d"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","configPath":"/opt/build/repo/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/react-i18next","i18nextOptions":{"ns":["translation"],"fallbackLng":"en"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
