import React, { useEffect, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";

import { Desktop } from "@contexts/desktop";
import { Location } from "@contexts/location";

import { DESKTOP_MEDIA_QUERY } from "../settings";

import "../stylesheets/app.scss";

const mediaQueryList =
  typeof window !== "undefined" && matchMedia(DESKTOP_MEDIA_QUERY);

const App = ({ location, children }) => {
  const [isDesktop, setIsDesktop] = useState(
    mediaQueryList && mediaQueryList.matches
  );
  const handleViewChange = (e) => {
    setIsDesktop(e.matches);
  };

  useEffect(() => {
    mediaQueryList.addEventListener("change", handleViewChange);

    return () => {
      mediaQueryList.removeEventListener("change", handleViewChange);
    };
  }, []);

  return (
    <Location.Provider value={location}>
      <Desktop.Provider value={isDesktop}>
        <TransitionGroup component={null}>
          <CSSTransition
            key={location.pathname}
            classNames="page"
            addEndListener={(node, done) => {
              // use the css animationend event to mark the finish of an animation
              node.addEventListener("animationend", done, false);
            }}
          >
            {React.Children.only(children)}
          </CSSTransition>
        </TransitionGroup>
      </Desktop.Provider>
    </Location.Provider>
  );
};

App.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  children: PropTypes.node.isRequired,
};

export default App;
