import CustomLayout from "./wrapPageElement";
import {
  MOBILE_CLASSNAME,
  DESKTOP_CLASSNAME,
  DESKTOP_MEDIA_QUERY,
} from "./src/settings";

export const wrapPageElement = CustomLayout;

export const onClientEntry = () => {
  const isDesktop = matchMedia(DESKTOP_MEDIA_QUERY).matches;
  const elementsToDelete = document.getElementsByClassName(
    isDesktop ? MOBILE_CLASSNAME : DESKTOP_CLASSNAME
  );

  // delete unused elements to sync DOM with the following React hydration state
  Array.prototype.forEach.call(elementsToDelete, (el) => el.remove());
};
